import React from "react";
import './Coin.css';

const Coin = ({ name, price, symbol, marketcap, volume, image, priceChange }) => {
	return (
		<div className="coin-container">
			<div className="coin-row">
				<div className="coin">
					<img src={image} alt="cryptocurrency" />
					<h1>{name}</h1>
					<p className="coin-symbol">{symbol}</p>
				</div>
				<div className="coin-data">
					<p className="coin-price">{price} USD</p>
					<p className="coin-volume">${volume.toLocaleString()}</p>

                    {priceChange < 0 ? (
                        // 'toFixed(2)' is to put two decimal places
                        <p className="coin-percent red">{priceChange.toFixed(2)}%</p>
                    ) : (
                        <p className="coin-percent green">{priceChange.toFixed(2)}%</p>
                    )}

                    <p className="coin-marketcap">Market Cap: ${marketcap.toLocaleString()}</p>
				</div>
			</div>
		</div>
	);
};

export default Coin;
